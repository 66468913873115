/* eslint-disable */
import copy from 'copy-to-clipboard';
import { t } from 'i18next';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { NotificationManager } from 'react-notifications';
import { useDispatch } from 'react-redux';
import { api } from 'src/api/api';
import copyimg from 'src/assets/images/copy.svg';
import loading from 'src/assets/images/loading.gif';
import { BalanceOutStatusEnum } from 'src/common/provider-common-type-util/dictionary/balance';
import Button from 'src/components/buttonV2/Button';
import Input from 'src/components/inputV2/input';
import { modalTypes } from 'src/components/modals/Modal';
import OutlinedDropdown from 'src/components/outlinedDropdownV2/OutlinedDropdown';
import './deposit.scss';
import { setCloseModal } from 'src/core/store/reducers/appSlice';
import { formatUserTimeZone } from 'src/utils/helpers';
import arrowImg from 'src/assets/images/arrow.svg';

import { validateTRC20Helper } from './validate';
import { useSelector } from 'src/core/store/store';
import { CurrencyEnum } from 'src/common/provider-common-type-util/dictionary/currency';

const Deposit = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingWallet, setIsLoadingWallet] = useState(false);
    const [isLoadingOut, setIsLoadingOut] = useState(false);
    const [deposit, setDeposit] = useState(null);
    const [wallet, setWallet] = useState<string>(null);
    const [amount, setAmount] = useState('');
    const [currency, setCurrency] = useState({ label: 'USDT-TRC20', value: 'USDT-TRC20' });
    const [destinationAddress, setDestinationAddress] = useState('');
    const dispatch = useDispatch();
    // @ts-ignore
    const user = useSelector((state) => state.user?.user);

    const getDeposit = async () => {
        setIsLoading(true);
        const resp = await api.get('/balance/out/player');
        if (resp.data) {
            setDeposit(resp.data);
            setIsLoading(false);
        } else {
            setIsLoading(false);
        }
    };

    const optionsCurrency = Object.values(CurrencyEnum).map((currency) => ({
        label: currency,
        value: currency,
    }));

    const onCopy = useCallback(() => {
        NotificationManager.success(`${t('copied')}`, '', 2000);
        copy(wallet);
    }, [wallet]);

    const getWallet = async () => {
        setIsLoadingWallet(true);
        const resp = await api.get(`/balance/in/address?currency=${currency.value}`);
        if (resp.data) {
            setIsLoadingWallet(false);
            setWallet(resp.data as string);
        } else {
            setIsLoadingWallet(false);
        }
    };

    const createCashWithdrawal = async () => {
        const options = {
            amount: Number(amount) * 100,
            destinationAddress,
            currency: currency.value,
        };
        setIsLoadingOut(true);
        const resp = await api.post('/balance/out/create', options);
        if (resp.data) {
            setIsLoadingOut(false);
            setDeposit(resp.data);
        } else {
            setIsLoadingOut(false);
        }
    };

    const handleChangeAmount = (e: ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        const REGEX_NUMBERS = '^([0-9]{1,})?(\\.)?([0-9]{1,})?$';
        if (value.match(REGEX_NUMBERS)) {
            setAmount(value);
        }
    };

    useEffect(() => {
        getDeposit();
    }, []);

    const showCreateBalance = () => {
        if (deposit === null) return true;
        if (deposit.status === BalanceOutStatusEnum.BALANCE_OUT_STATUS_FINISHED) return true;
        return false;
    };

    const handleChangeCurrency = (element: { value: string; label: string }) => {
        setCurrency(element);
    };

    const isDisabledButton = () => {
        return false;
    };

    const handleChangeDestinationAddress = (e: ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setDestinationAddress(value);
    };

    if (isLoading)
        return (
            <div className="Deposit-loading">
                <img src={loading} alt="" />
            </div>
            // <div className="Deposit-loading">
            //     <Loading />
            // </div>
        );
    return (
        <div className="Deposit">
            <div className="Deposit-header">
                <div className="Deposit-goback" onClick={() => dispatch(setCloseModal(modalTypes.DEPOSIT))}>
                    <img src={arrowImg} alt="arrow" className="Deposit-arrow" />
                    <span>{t('back')}</span>
                </div>
            </div>
            {/*<div className="Deposit-xmark" onClick={() => dispatch(setCloseModal(modalTypes.DEPOSIT))}>*/}
            {/*    <CloseIcon />*/}
            {/*</div>*/}
            <div className="Deposit-balance">
                <div className="Deposit-balance-title">{t('balance')}</div>
                <div className="Deposit-balance-content">
                    <div className="Deposit-balance-content-title">{t('withdrawable')}</div>
                    <div className="Deposit-balance-content-balance">
                        ${user.balance ? ((user.balance + user.scedualPaymentBalance) / 100)?.toFixed(2) : 0}$
                    </div>
                </div>
            </div>
            <div className="Deposit-get-wallet">
                <span className="Deposit-get-wallet-title">{t('deposit_balance')}</span>
                <div className="Deposit-get-wallet-content">
                    {wallet === null && (
                        <div className="Deposit-get-wallet-content-form">
                            <OutlinedDropdown
                                className="Deposit-get-wallet-content-form-dropdown"
                                placeholder={t('currency')}
                                options={optionsCurrency}
                                onSelect={handleChangeCurrency}
                                defaultSelected={currency}
                            />
                            <Button
                                className="Deposit-get-wallet-content-form-button"
                                title={t('get')}
                                primary
                                disabled={isDisabledButton()}
                                onClick={getWallet}
                                loading={isLoadingWallet}
                            />
                        </div>
                    )}
                    {wallet && (
                        <div className="Deposit-get-wallet-content-wallet" onClick={onCopy}>
                            <code>{wallet}</code>
                            <img src={copyimg} alt="copy" className="Deposit-copy-icon" />
                        </div>
                    )}
                </div>
            </div>
            {showCreateBalance() && (
                <div className="Deposit-cash-withdrawal">
                    <span className="Deposit-cash-withdrawal-title">{t('withdraw')}</span>
                    <div className="Deposit-cash-withdrawal-form">
                        <div className="Deposit-cash-withdrawal-form-item">
                            <Input
                                className="Deposit-input"
                                hideLabel
                                placeholder={t('amount')}
                                value={amount}
                                onChange={handleChangeAmount}
                            />
                            <OutlinedDropdown
                                className="Deposit-cash-withdrawal-form-item-dropdown"
                                placeholder={t('currency')}
                                options={optionsCurrency}
                                onSelect={handleChangeCurrency}
                                defaultSelected={currency}
                            />
                        </div>
                        <div className="Deposit-cash-withdrawal-form-item">
                            <Input
                                className="Deposit-input"
                                hideLabel
                                placeholder={t('destinationAddress')}
                                value={destinationAddress}
                                onChange={handleChangeDestinationAddress}
                                error={validateTRC20Helper(destinationAddress)}
                            />

                            <Button
                                title={t('confirm')}
                                className="Deposit-button"
                                primary
                                onClick={createCashWithdrawal}
                                loading={isLoadingOut}
                            />
                        </div>
                    </div>
                </div>
            )}
            {!isLoading && deposit && (
                <div className="Deposit-table">
                    <div className="Deposit-table-heading">
                        <div className="Deposit-table-heading-item">{t('latest_withdrawal')}</div>
                    </div>
                    <div className="Deposit-table-body">
                        <div className="Deposit-table-row">
                            <div className="Deposit-table-row-item-first">id</div>
                            <div className="Deposit-table-row-item">{deposit.id}</div>
                        </div>
                        <div className="Deposit-table-row">
                            <div className="Deposit-table-row-item-first">{t('amount')}</div>
                            <div className="Deposit-table-row-item">{deposit.amount ? (deposit.amount / 100).toFixed(2) : 0}</div>
                        </div>
                        <div className="Deposit-table-row">
                            <div className="Deposit-table-row-item-first">{t('target_current_balance')}</div>
                            <div className="Deposit-table-row-item">
                                {deposit.targetCurrentBalance ? (deposit.targetCurrentBalance / 100).toFixed(2) : 0} $
                            </div>
                        </div>
                        <div className="Deposit-table-row">
                            <div className="Deposit-table-row-item-first">{t('destinationAddress')}</div>
                            <div className="Deposit-table-row-item">{deposit.destinationAddress}</div>
                        </div>
                        <div className="Deposit-table-row">
                            <div className="Deposit-table-row-item-first">{t('sourceAddress')}</div>
                            <div className="Deposit-table-row-item">{deposit.sourceAddress}</div>
                        </div>
                        <div className="Deposit-table-row">
                            <div className="Deposit-table-row-item-first">{t('status')}</div>
                            <div className="Deposit-table-row-item">
                                {BalanceOutStatusEnum[deposit.status]
                                    ? BalanceOutStatusEnum[deposit.status].split('BALANCE_OUT_STATUS_')[1]
                                    : '-'}
                            </div>
                        </div>
                        <div className="Deposit-table-row">
                            <div className="Deposit-table-row-item-first">{t('reason')}</div>
                            <div className="Deposit-table-row-item">{deposit.reason}</div>
                        </div>
                        <div className="Deposit-table-row">
                            <div className="Deposit-table-row-item-first">{t('date')}</div>
                            <div className="Deposit-table-row-item">
                                {formatUserTimeZone(deposit.createDate as string).split('T')[0]}{' '}
                                {deposit.createDate.split('T')[1].split('.')[0]}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Deposit;
